import {firestore} from "../configs/firebase";
import Wallet from './wallet';

class User{
    constructor(userdata=new Model(), user_id='') {
        this.id = user_id;
        this.data = Object.assign(new Model(), userdata)
        this.store_data = userdata
    }

    storeToModel(){
        const tmp_user = Object.assign(new Model(), this.store_data);
        let _date = new Date(0);
        _date.setSeconds(tmp_user.created_at.seconds)
        tmp_user.created_at = _date;
        _date = new Date(0);
        _date.setSeconds(tmp_user.updated_at.seconds)
        tmp_user.updated_at = _date;
        return tmp_user
    }
    async assetsToFiat() {
        let cryptos = Object.entries(this.data.assets).filter(crypt => crypt[1] > 0);
        let all_crypto_ids = cryptos.map((asset) => asset[0]);
        if (cryptos.length > 0) {
            let result = (await Wallet.cryptoToFiatAll(this.data.currency,all_crypto_ids)).data.value;
            let results = result.map((cryptoObjResult) => {
                const currency = cryptoObjResult['currency'];
                const prices = cryptoObjResult['prices'];
                let amount = 0;
                cryptos.forEach(cryptObj => {
                    if (cryptObj[0] === currency) {
                        amount = Number.parseFloat(prices[prices.length - 1]);
                        amount = amount * Number.parseFloat(cryptObj[1]);
                    }
                });
                return amount
            });
            return results.reduce((x, y) => x + y, 0);
        }
    }
}

function Model(){
    this.email = '';
    this.phone = '';
    this.name = '';
    this.address = '';
    this.assets = {}    // {<crypto-asset-id>:<amount>}
    this.auto_trade_assets = {}     // {<crypto-asset-id> : {enabled: bool, amount: <amount>, created_at: <datetime>, updated_at: <datetime>, interest: 0 }, ... }
    this.currency = 'USD'  // <fiat-id>
    this.referred = false;
    this.referred_by = ''    // <user-id>
    this.is_admin = false;
    this.email_is_verified = false;
    this.tfa_enabled = false;
    this.pass_phrase = [];
    this.created_at = firestore.FieldValue.serverTimestamp()
    this.updated_at = firestore.FieldValue.serverTimestamp();
}

export const UserModel = Model;
export default User