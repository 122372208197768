import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import "firebase/storage"

// const production = window.location.hostname === 'metricswallet.com' || window.location.hostname === 'www.metricswallet.com';
let firebaseConfig = {
    apiKey: "AIzaSyBos4cz83ZlLKMed32IrPM5HW5gIDApLd8",
    authDomain: "reliaearn-f2293.firebaseapp.com",
    projectId: "reliaearn-f2293",
    storageBucket: "reliaearn-f2293.appspot.com",
    messagingSenderId: "69677091317",
    appId: "1:69677091317:web:26fa27b2c93c42684b38bc",
    measurementId: "G-G8RDYXH6GM"
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore;
export const storageRef = firebase.storage().ref();
export const users_store = firestore().collection('users');
export const users_profile_store = firestore().collection('userProfiles');
export const transactions_store = firestore().collection('transactions');
export const receive_payments_store = firestore().collection('receivePayments');
export const send_payments_store = firestore().collection('sendPayments');
export const crypto_assets_store = firestore().collection('cryptoAssets');
export const fiat_assets_store = firestore().collection('fiatAssets');
export const wallet_addresses_store = firestore().collection('wallets');
export const supports_store = firestore().collection('support');
export const card_application_store = firestore().collection('cardApplications');
export const hw_application_store = firestore().collection('HWTokens');
export const contact_store = firestore().collection('contacts');
export default firebase