import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

// modules
import notification from "./modules/notification";
import auth from "./modules/auth";
import user from "./modules/user";
import wallet from "./modules/wallet";
import transaction from "./modules/transaction";
import support from "./modules/support";
import card from "./modules/card";
import HWToken from './modules/HWToken';
import contact from './modules/contact';

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        key: 'reliaearn',
        storage: {
            getItem: (key) => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key),
        },
    })],
    state: {

    },
    mutations: {
    },
    actions: {
    },
    modules: {
        notification,
        auth,
        user,
        wallet,
        transaction,
        support,
        card,
        HWToken,
        contact
    }
})
