import {firestore, supports_store} from "../../configs/firebase";
import globalObjects from "../../utils/globalObjects";

export default {
  namespaced: true,
  state: {
    messages: [],
    all_messages: [],
    listener: null,
    messages_listener: null
  },
  getters: {
    getMessages: (state) => state.messages,
    getAllMessages: (state) =>state.all_messages
  },
  mutations: {
    reset(state){
      if(state.listener !== null){
        state.listener()
      }
      if(state.messages_listener !== null){
        state.messages_listener()
      }
      state.messages_listener = state.listener = null;
      state.messages = state.all_messages = [];
    },
    setMessages: (state, payload) => state.messages = payload,
    setAllMessages: (state, payload) => state.all_messages = payload
  },
  actions: {
    async fetchAllSupportMessages({state, commit}){
      state.messages_listener = supports_store
      .onSnapshot(snapshot => {
        let tmp_arr = [];
        snapshot.forEach(doc=>{
          if(doc.exists){
            tmp_arr.push({
              id: doc.id,
              data: doc.data()
            })
          }
        });
        commit('setAllMessages', tmp_arr)
      })
    },
    // Not Used
    async fetchUserConversation(context, userId){
      const response = new globalObjects.CustomResponse();
      response.data = [];
      try{
        let messages = await supports_store
        .doc(userId)
        .collection('messages')
        .get();
        if(!messages.empty){
          messages.forEach(doc=>{
            if(doc.exists){
              response.data.push({
                id: doc.id,
                data: doc.data()
              })
            }
          })
        }
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response);
    },
    async fetchUserMessages({state, commit, rootGetters}){
      state.listener = supports_store
      .doc(rootGetters['user/getUser'].id)
      .collection('messages')
      .orderBy('created_at')
      .onSnapshot((snapshot)=>{
        let tmp_arr = [];
        snapshot.forEach(doc=>{
          if(doc.exists){
            tmp_arr.push({
              id: doc.id,
              data: doc.data()
            })
          }
        });
        commit('setMessages', tmp_arr);
        var scr = $('#appCapsule')[0].scrollHeight;
        $('#appCapsule').animate({scrollTop: scr},2000);
      });
    },
    async markMsgAsRead({rootGetters}, userId){
      const response = new globalObjects.CustomResponse();
      const user = rootGetters['user/getUser'];
      const update_obj = {};
      if(user.data.is_admin){
        update_obj['unread'] = 0;
      }else{
        update_obj['u_unread'] = 0;
      }
      try{
        let supportDoc = await supports_store.doc(userId).get();
        if(supportDoc.exists){
          await supports_store.doc(userId).update(update_obj)
        }
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },
    async sendUserMessage({rootGetters}, message){
      const response = new globalObjects.CustomResponse();
      const curr_user = rootGetters['user/getUser'];
      try{
        let supportDoc = await supports_store.doc(curr_user.id).get();
        if(supportDoc.exists){
          await supports_store
          .doc(curr_user.id)
          .update({
            // u_unread: firestore.FieldValue.increment(1),
            unread: firestore.FieldValue.increment(1)
          });
        }else{
          await supports_store
          .doc(curr_user.id)
          .set({
            // u_unread: 1,
            unread: 1
          });
        }
        await supports_store
        .doc(curr_user.id)
        .collection('messages')
        .add(Object.assign({}, message))
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },
    async sendMessageFromAdmin(context, {message, toId}){
      const response = new globalObjects.CustomResponse();
      try{
        let supportDoc = await supports_store.doc(toId).get();
        if(supportDoc.exists){
          await supports_store
          .doc(toId)
          .update({
            unread: firestore.FieldValue.increment(1)
          });
        }else{
          await supports_store
              .doc(toId)
              .set({
                unread: 1
              });
        }
        await supports_store
            .doc(toId)
            .collection('messages')
            .add(Object.assign({}, message))
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },
    async getUnreadMessages({rootGetters}){
      const response = new globalObjects.CustomResponse();
      response.data = 0;
      const user = rootGetters['user/getUser'];
      try{
        let supportDoc = await supports_store.doc(user.id).get();
        if(supportDoc.exists){
          if(!user.data.is_admin) response.data = supportDoc.data().u_unread;
        }else{
          response.set_status(false)
        }
      }catch (e){
        response.set_status(false, true)
      }
      return Promise.resolve(response)
    }
  }
}