import globalObjects from "../../utils/globalObjects";
import {
    hw_application_store,
} from '../../configs/firebase';
import HWApplication from '../../models/hwApplication';

export default {
    namespaced: true,
    state: {
        tokens: [],
        listener: null
    },
    getters: {
        getTokens: state => state.tokens
    },
    mutations: {
        setTokens: (state, payload) => state.tokens = payload,
        reset(state){
            if(state.listener !== null) this.state.listener();
            state.listener = null;
            state.tokens = [];
        }
    },
    actions: {
        async fetchAllTokens({state, commit}){
            state.listener = hw_application_store
            .onSnapshot(snapshot=>{
                let tmp_arr = [];
                snapshot.forEach(doc=>{
                    if(doc.exists){
                        tmp_arr.push({
                            id: doc.id,
                            data: doc.data()
                        })
                    }
                });
                commit('setTokens', tmp_arr)
            })
        },
        async fetchTokens({rootGetters}){
            const response = new globalObjects.CustomResponse();
            response.data = [];
            try{
                let snapshots = await hw_application_store
                .where('user', '==', rootGetters['user/getUser'].id)
                .get();
                if(!snapshots.empty){
                    snapshots.forEach(doc=>{
                        if(doc.exists){
                            response.data.push({
                                id: doc.id,
                                data: doc.data()
                            })
                        }
                    })
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async addNewApplication(context, token){
            const response = new globalObjects.CustomResponse();
            try{
                const new_application = new HWApplication(token);
                await new_application.save()
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async updateApplication(context, {token, status}){
            const response = new globalObjects.CustomResponse();
            const updateObj = {
                status
            }
            try{
                await hw_application_store.doc(token.id).update(updateObj)
            }catch (e){
                response.set_status(false, e);
            }
            return Promise.resolve(response)
        }
    }
}