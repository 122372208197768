import GlobalFunctions from "./globalFunctions";
import {UserModel} from "../models/user";
import {TransactionModel} from "../models/transaction";

class GlobalFuncPlugin{
    // eslint-disable-next-line no-unused-vars
    static install(Vue, options={}){
        Vue.prototype.$globalFunc = GlobalFunctions;
        Vue.prototype.$loader = {
            show: () => $('#loader').fadeIn(),
            hide: () => $('#loader').fadeOut()
        }
        Vue.prototype.$notifySpam = ()=>{
            $('#emailSpam').modal('show')
        }
        // Vue.mixin({
        //     created(){
        //         console.log('Global functions initialized')
        //     }
        // })
    }
}

class GlobalModelsPlugin{
    // eslint-disable-next-line no-unused-vars
    static install(Vue, options={}){
        Vue.prototype.$models = {
            UserModel,
            TransactionModel
        }
    }
}


export const ModelsPlugin = GlobalModelsPlugin
export const FunctionPlugins = GlobalFuncPlugin