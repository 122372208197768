import globalObjects from "../utils/globalObjects";
import GlobalObjects from "../utils/globalObjects";

class Wallet{
  static NMS_API_KEY = 'abfff9b7aaa27fc6bdf3152253a9ed1f6a485b8d';
  constructor() {

  }

  static getTradeInterest(amount){
    if((amount >= 1) && (amount <= 499)) return (0.50/14) // 50% - 90 days
    if((amount >= 500) && (amount <= 9999)) return (0.50/20) // 50%
    if((amount >= 10000) && (amount <= 50000)) return (0.50/30) // 200%
    if((amount >= 50000) && (amount <= 250000)) return (1.5/60) // 400%
    return 0
  }
  static async cryptoToFiat(currency, crypto_id){
    const response = new globalObjects.CustomResponse();
    response.data.value = 0;
    const body = {
      key: Wallet.NMS_API_KEY,
      ids: crypto_id,
      convert: currency
    };
    if(body.convert.toUpperCase() === 'USD'){
      body.convert = 'USDT';
    }
    try {
      if(body.ids.toUpperCase() === 'USDT' || body.ids.toUpperCase() === 'HOC'){
        body.ids = 'USDC';
      }
      // because it is US
      if(body.ids.toUpperCase() === 'DASH'){
        body.convert = 'USD';
      }
      if(body.ids.toUpperCase() === 'LUNA' || body.ids.toUpperCase() === 'NEXO' || body.ids.toUpperCase() === 'DIA' || body.ids.toUpperCase() === 'UTK'){
        body.ids = 'USDC';
      }
      // end US reason
      const status = await GlobalObjects.MWInstance.get(`/get-all-symbol-pair-price?symbol=${body.ids}${body.convert}`,)
      response.data.value = Number.parseFloat(status.data.price);
    } catch (e){
      console.log("Crypto query error:", JSON.stringify(e));
      response.set_status(false, e);
    }
    return Promise.resolve(response);
  }

  static async cryptoToFiatAll(currency, crypto_ids){
    const response = new globalObjects.CustomResponse();
    response.data.value = [];
    const body = {
      key: Wallet.NMS_API_KEY,
      ids: crypto_ids,
      convert: currency
    };
    if(body.convert.toUpperCase() === 'USD'){
      body.convert = 'USDT';
    }
    try {
      const cryptoPriceHistory = {};
      // let url = "api.binance.com";
      let isUS = true;
      const status = await GlobalObjects.MWInstance.get('/get-all-symbol-pair-price')
      const promises = body.ids.map((id)=>{
        let cryptoId = id.toUpperCase();
        let convertTo = "USDT";
        switch (cryptoId.toUpperCase()){
          case 'USDT': {
            cryptoId = 'USDC';
            break;
          }
          case 'HOC': {
            cryptoId = 'USDC';
            break;
          }
          case 'HARMONY': {
            cryptoId = 'ONE';
            break;
          }
          case 'KCS': {
            cryptoId = 'CKB';
            break;
          }
          case 'DASH': {
            if(isUS){
              convertTo = "USD";
            }
            // convertTo = "USD"; // remove line before publish
            break;
          }
          case 'LUNA':
          case 'NEXO':
          case 'DIA':
          case 'UTK': {
            if(isUS){
              cryptoId = 'USDC';
            }
            // cryptoId = 'USDC'; // remove line before publish
            break;
          }
        }
        return GlobalObjects.MWInstance.post('/get-all-trades', {
          symbol: `${cryptoId}${convertTo}`,
          limit:500
        })
      });
      const results = await Promise.all(promises);
      results.forEach((result, index)=>{
        cryptoPriceHistory[body.ids[index].toUpperCase()] = Array.from( new Set(result.data.map((price)=>price.price)) );
      });
      const cryptoPrices = status.data;
      for(const cryptoID of body.ids){
        let cryptoId = cryptoID;
        let convertTo = body.convert;
        switch (cryptoId.toUpperCase()){
          case 'USDT': {
            cryptoId = 'USDC';
            break;
          }
          case 'HOC': {
            cryptoId = 'USDC';
            break;
          }
          case 'HARMONY': {
            cryptoId = 'ONE';
            break;
          }
          case 'KCS': {
            cryptoId = 'CKB';
            break;
          }
          case 'DASH': {
            if(isUS){
              convertTo = "USD";
            }
            // convertTo = "USD"; // remove line before publish
            break;
          }
          case 'LUNA':
          case 'NEXO':
          case 'DIA':
          case 'UTK': {
            if(isUS){
              cryptoId = 'USDC';
            }
            // cryptoId = 'USDC'; // remove line before publish
            break;
          }
        }
        const result = cryptoPrices.filter((priceObj)=>priceObj.symbol===(cryptoId+convertTo).toUpperCase());
        if(result.length === 1){
          response.data.value.push({
            currency: cryptoID,
            // prices: [result[0].price],
            prices: cryptoPriceHistory[cryptoId.toUpperCase()] || [result[0].price],
          });
        }else{
          console.log(`Cannot find for ${cryptoID}`);
          response.data.value.push({
            currency: cryptoId,
            prices: [0],
          });
        }
      }
    } catch (e){
      console.log("Crypto query all error:", e);
      response.set_status(false, e);
    }
    return Promise.resolve(response);
  }

}

function Model(){
  this.addresses = []
}

export const WalletModel = Model
export default Wallet
