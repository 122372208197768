class UserProfile{
  constructor() {
    this.data = new Model()
  }
}

function Model(){
  this.balance = 0;
  this.cardEnabled = false;
  this.hwEnabled = false;
  this.email_ver_ref = '';
  this.password_reset_ref = '';
  // this.auto_trade = {}
}

export const UserProfileModel = Model;
export default UserProfile