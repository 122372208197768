import Payment from "./payment";

class ReceivePayment extends Payment{
    constructor() {
        super();
        this.data = Object.assign(this._data, new Model())
    }
}

function Model(){
    this.api_response = {};
    this.api_response_status = 'PENDING'; // PENDING, COMPLETED, FAILED, CREATED
    this.api_webhook = {
        created: {},
        confirmed: {},
        failed: {},
        delayed: {},
        pending: {},
    };
    this.completed = false;
    this.overrideConfirmation = false;
    this.paid_through = 'MERCHANT'
}

export default ReceivePayment