import globalObjects from "../../utils/globalObjects";
import {card_application_store, firestore} from '../../configs/firebase';
import CardApplication from '../../models/cardApplication';

export default {
    namespaced: true,
    state: {
        cards: [],
        listener: null
    },
    getters: {
        getCards: state => state.cards
    },
    mutations: {
        setCards: (state, payload) => state.cards = payload,
        reset(state){
            if(state.listener !== null) this.state.listener();
            state.listener = null;
            state.cards = [];
        }
    },
    actions: {
        async fetchAllCards({state, commit}){
            state.listener = card_application_store
            .onSnapshot(snapshot=>{
                let tmp_arr = [];
                snapshot.forEach(doc=>{
                    if(doc.exists){
                        tmp_arr.push({
                            id: doc.id,
                            data: doc.data()
                        })
                    }
                });
                commit('setCards', tmp_arr)
            })
        },
        async fetchCards({rootGetters}){
            const response = new globalObjects.CustomResponse();
            response.data = [];
            try{
                let snapshots = await card_application_store
                .where('user', '==', rootGetters['user/getUser'].id)
                .where('status', '==', 'APPROVED')
                .get();
                if(!snapshots.empty){
                    snapshots.forEach(doc=>{
                        if(doc.exists){
                            response.data.push({
                                id: doc.id,
                                data: doc.data()
                            })
                        }
                    })
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async addNewApplication(context, card){
            const response = new globalObjects.CustomResponse();
            try{
                const new_application = new CardApplication(card);
                await new_application.save()
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async updateApplication(context, {card, status}){
            const response = new globalObjects.CustomResponse();
            const updateObj = {
                status,
                assignedNumber: '',
                assignedCVV: ''
            }
            try{
                switch (status){
                    case 'APPROVED': {
                        let card_number = '42639';
                        let card_cvv = []
                        for(let count=0; count < 11; count++){
                            card_number += Math.floor(Math.random()*9)
                        }
                        for(let count=0; count < 3; count++){
                            card_cvv.push(Math.floor(Math.random()*9))
                        }
                        updateObj.assignedNumber = card_number;
                        updateObj.assignedCVV = card_cvv.join("")
                        const _date = new Date(0);
                        _date.setSeconds(card.data.createdAt.seconds);
                        _date.setFullYear(_date.getFullYear()+2);
                        updateObj.expireAt = firestore.Timestamp.fromDate(_date);
                        break;
                    }
                }
                await card_application_store.doc(card.id).update(updateObj)
            }catch (e){
                response.set_status(false, e);
            }
            return Promise.resolve(response)
        }
    }
}