import {firestore} from "../configs/firebase"
class Contact{
    constructor(contact={}, contact_id="") {
        this.id = contact_id;
        this.data = Object.assign(new Model(), contact)
    }
}

function Model(){
    this.name = "";
    this.email = "";
    this.message = "";
    this.is_read = false;
    this.createdAt = firestore.FieldValue.serverTimestamp();
}

export default Contact