<template>
    <div>
        <!-- ios style 7-->
        <div id="noti-ios-default" class="notification-box">
            <div class="notification-dialog ios-style">
                <div class="notification-header">
                    <div class="in">
                        <img src="../assets/img/logo-icon.png" alt="image" class="imaged w24 rounded">
                        <strong>EarnRelai</strong>
                    </div>
                    <div class="right">
                        <span style="font-size: 9px">{{getDatetime(getContent.timestamp)}}</span>
                        <a @click="closeNoti('noti-ios-default')" href="javascript:void(0)" class="close-button">
                            <ion-icon name="close-circle"></ion-icon>
                        </a>
                    </div>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">{{getContent.content.title}}</h3>
                        <div class="text" v-html="getContent.content.message"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * ios style 7 -->

        <!-- ios style 11 -->
        <div id="noti-ios-buttons" class="notification-box">
            <div class="notification-dialog ios-style">
                <div class="notification-header">
                    <div class="in">
                        <img src="../assets/img/logo-icon.png" alt="logo" class="imaged w24 rounded">
                        <strong>EarnRelai</strong>
                    </div>
                    <div class="right">
                        <span style="font-size: 9px">{{getDatetime(getContent.timestamp)}}</span>
                        <a  @click="closeNoti('noti-ios-buttons')" href="javascript:void(0)" class="close-button">
                            <ion-icon name="close-circle"></ion-icon>
                        </a>
                    </div>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">{{getContent.content.title}}</h3>
                        <div class="text" v-html="getContent.content.message"></div>
                    </div>
                </div>
                <div class="notification-footer">
                    <template v-if="getContent.content.buttons.length > 0">
                        <a @click="button.callback()" v-for="(button, idx) in getContent.content.buttons" :key="idx" href="javascript:void(0)" class="notification-button">
                            <span v-html="button.name"></span>
<!--                            <ion-icon name="wallet-outline"></ion-icon>-->
<!--                            My Wallet-->
                        </a>
                    </template>

<!--                    <a href="javascript:void(0)" class="notification-button">-->
<!--                        <ion-icon name="card-outline"></ion-icon>-->
<!--                        Debit Card-->
<!--                    </a>-->
                </div>
            </div>
        </div>
        <!-- * ios style 11-->

    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import methodsMixin from "../utils/methodsMixin"

export default {
    name: "Notification",
    data(){
        return {

        }
    },
    mixins: [methodsMixin],
    computed: {
        ...mapGetters('notification', [
            'getContent'
        ])
    },
    methods: {
        ...mapActions('notification', [
            'fire', 'closeNoti'
        ])
    }
}
</script>

<style scoped>

</style>