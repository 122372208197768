import firebase, {users_profile_store, users_store} from "../../configs/firebase";
import GlobalObjects from "../../utils/globalObjects";
import {UserProfileModel} from "../../models/userProfile";
import router from "../../router";
import globalObjects from "../../utils/globalObjects";
import GlobalFunctions from "../../utils/globalFunctions";
import {sendWelcomeEmail} from "../../repository/listener";

export default {
    namespaced: true,
    state: {
        signedIn: false,
        showTFAModal: false,
        showPassphraseModal: false
    },
    getters: {
        isSignedIn(state){
            firebase.auth().onAuthStateChanged((user) => {
                state.signedIn = !!user
            });
            return state.signedIn
        },
        getTFAModal: state => state.showTFAModal,
        getPassphraseModal: state => state.showPassphraseModal,
    },
    mutations: {
        setTFAModal: (state, payload) => state.showTFAModal=payload,
        setPassphraseModal: (state, payload) => state.showPassphraseModal=payload,
    },
    actions: {
        async register(context, {user, password}){
            let response = new GlobalObjects.CustomResponse();
            try{
                // await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
                let auth_result = await firebase.auth().createUserWithEmailAndPassword(user.email, password);
                await users_store
                  .doc(auth_result.user.uid)
                  .set(Object.assign({}, user));
                await users_profile_store.doc(auth_result.user.uid).set(Object.assign({}, new UserProfileModel()));
                await sendWelcomeEmail({
                    id: auth_result.user.uid,
                    data: user
                })
                try{
                    await firebase.auth().signOut();
                }catch (e){
                    console.warn(e)
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async login({dispatch}, {email, password}){
            const response = new GlobalObjects.CustomResponse();
            response.data.tfa_enabled = false;
            response.data.tfa_code = '';
            try{
                await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
                const u_credential = await firebase.auth().signInWithEmailAndPassword(email, password);
                const user = await dispatch('user/getUser', u_credential.user.uid, {root: true});
                if(user.status){
                    if(user.data.data.tfa_enabled){
                        try{
                            await firebase.auth().signOut();
                        }catch (e){
                            console.warn(e)
                        }
                        response.data.tfa_enabled = true;
                        const sms_response = (await globalObjects.MWInstance.post('user/phone-auth', {
                            to: user.data.data.phone,
                            user_id: user.data.id
                        })).data;
                        if(sms_response.status){
                            response.data.tfa_code = sms_response.data.sms_code
                        }
                    }else{
                        await dispatch('user/initUser', {
                            user_id: u_credential.user.uid,
                            profile_id: u_credential.user.uid
                        }, {root: true})
                    }
                }else{
                    response.set_status(false, new Error(user.message))
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async loginAfterTFA({dispatch}, {email, password}){
            const response = new GlobalObjects.CustomResponse();
            try{
                await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
                const u_credential = await firebase.auth().signInWithEmailAndPassword(email, password);
                await dispatch('user/initUser', {
                    user_id: u_credential.user.uid,
                    profile_id: u_credential.user.uid
                }, {root: true})
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async logout({commit}){
            try{
                await firebase.auth().signOut()
            }catch (e){
                console.warn(e)
            }finally {
                await router.push({name: 'Auth'});
                commit('transaction/reset', null, {root: true});
                commit('user/reset', null, {root: true});
                commit('wallet/reset', null, {root: true});
                commit('support/reset', null, {root: true});
                commit('card/reset', null, {root: true});
            }
        },
        async verifyEmail({rootGetters}){
            let response = new globalObjects.CustomResponse();
            const now = String(new Date().valueOf());
            try{
                const _response = await globalObjects.MWInstance.post('user/verify-email', {
                    uid: rootGetters['user/getUser'].id,
                    link: GlobalFunctions.getFullURL(router.resolve({
                          name: 'EmailVerification',
                          params: {
                              userId: rootGetters['user/getUser'].id,
                              emailRef: now
                          }
                      }).href),
                    ref: now
                });
                response.data = _response.data;
            }catch (e){
                response.set_status(false, e);
            }
            return Promise.resolve(response)
        },
        async resetPassword(context, email){
            let response = new globalObjects.CustomResponse();
            const now = String(new Date().valueOf());
            try{
                const _response = await globalObjects.MWInstance.post('user/reset-password', {
                    uid: email,
                    link: GlobalFunctions.getFullURL(router.resolve({
                        name: 'PasswordReset',
                        params: {
                            email,
                            passRef: now
                        }
                    }).href),
                    ref: now
                });
                response.data = _response.data;
            }catch (e){
                response.set_status(false, e);
            }
            return Promise.resolve(response)
        },
        async updatePassword(context, {email, password}){
            const response = new globalObjects.CustomResponse();
            try{
                const _response = await globalObjects.MWInstance
                .post('user/update-password', {email,password});
                response.data = _response.data;
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async sendPassPhraseEmail({rootGetters}){
            let response = new globalObjects.CustomResponse();
            try{
                const _response = await globalObjects.MWInstance.post('user/recover-phrase', {
                    uid: rootGetters['user/getUser'].id,
                });
                response.data = _response.data;
            }catch (e){
                response.set_status(false, e);
            }
            return Promise.resolve(response)
        },
        async getUserByPassphrase(context, passphrase){
            const response = new globalObjects.CustomResponse();
            try{
                const usersDoc = await users_store .get();
                usersDoc.forEach(doc=>{
                    if(doc.exists){
                        const _passphrase = doc.data().pass_phrase;
                        if(Array.isArray(_passphrase)){
                            if(_passphrase.join("")===passphrase.join("")){
                                response.data = {
                                    id: doc.id,
                                    data: doc.data()
                                }
                            }
                        }
                    }
                })
                if(Object.entries(response.data).length <= 0){
                    throw new Error("We are unable to verify this passphrase, please check the order and try again.")
                }

            }catch (e){
                response.set_status(false,e)
            }
            return Promise.resolve(response)
        },
        async _login({dispatch}, {email, password}){
            const response = new GlobalObjects.CustomResponse();
            try{
                await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
                const u_credential = await firebase.auth().signInWithEmailAndPassword(email, password);
                const user = await dispatch('user/getUser', u_credential.user.uid, {root: true});
                if(user.status){
                    await dispatch('user/initUser', {
                        user_id: u_credential.user.uid,
                        profile_id: u_credential.user.uid
                    }, {root: true})
                }else{
                    throw new Error("We are unable to fetch your corresponding records")
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        }
    }
}