import globalObjects from "../utils/globalObjects";
const {users_store, firestore} = require("../configs/firebase");
const {AutoTradeModel} = require("../models/payments/payment");
const {UserRepository} = require("./userRepository");

export const creditAssetOnConversion = async (transaction) => {
    const data = transaction.data;
    try{
        if(data.context === 'CONVERT'){
            const user = await users_store.doc(data.user).get();
            if(user.exists){
                const user_assets = Object.assign({}, user.data().assets);
                // eslint-disable-next-line no-prototype-builtins
                if(user_assets.hasOwnProperty(data.metadata.to)){
                    user_assets[data.metadata.to] += Number.parseFloat(data.metadata.to_value);
                }else{
                    user_assets[data.metadata.to] = Number.parseFloat(data.metadata.to_value);
                }
                user_assets[data.metadata.from] = Math.max(0, user_assets[data.metadata.from]-Number.parseFloat(data.metadata.amount));
                await users_store.doc(data.user).update({
                    assets: user_assets,
                });
                const _user = {id: user.id, data: user.data()};
                const subject = "Crypto Converted";
                const templateID = 3260306;
                const variables = {
                    "email": user.data().email,
                    "name": user.data().name,
                    "from_amount": data.metadata.amount,
                    "from_cryptoId": data.metadata.from,
                    "to_amount": data.metadata.to_value,
                    "to_cryptoId": data.metadata.to,
                };
                const response = (await globalObjects.MWInstance.post('send-mail', {
                    user: _user,
                    subject,
                    templateID,
                    variables
                })).data;
                if(response.status){
                    return null;
                }
            }
        }
    }catch (e){
        console.log(e);
    }
    return Promise.resolve(null);
}

export const reSubscribeStakingOnNewDeposit = async (user, cryptoId) => {
    try{
        const new_data = user.data;
        const new_assets = user.data.assets;
        const new_asset = Number.parseFloat(new_assets[cryptoId]);
        const auto_trade_assets = new_data.auto_trade_assets;
        if(auto_trade_assets){
            if(Object.entries(auto_trade_assets).length > 0){
                if(!new_data.auto_trade_assets[cryptoId].withdrawn && new_data.auto_trade_assets[cryptoId].enabled){ // normal deposit made and not through staking withdrawal
                    if(new_asset > 0){
                        if(auto_trade_assets.elapse_active){ // staking is at one percent
                            const old_sub = auto_trade_assets.subscription;
                            if(Array.isArray(auto_trade_assets.old_subscriptions)){
                                auto_trade_assets.old_subscriptions.push(old_sub);
                            }
                            auto_trade_assets.subscription = AutoTradeModel.subscriptionModel;
                            auto_trade_assets.subscription.is_active = true;
                            auto_trade_assets.subscription.interest = old_sub.interest;
                            auto_trade_assets.subscription.amount = new_asset;
                            auto_trade_assets.subscription.plan_percentage = old_sub.plan_percentage;
                            auto_trade_assets.withdrawn = false;
                            auto_trade_assets.created_at = auto_trade_assets.updated_at = firestore.FieldValue.serverTimestamp();
                        }else{
                            auto_trade_assets.subscription.amount = new_asset;
                            auto_trade_assets.updated_at = firestore.FieldValue.serverTimestamp();
                        }
                        return users_store.doc(user.id).set({
                            auto_trade_assets,
                        }, {merge: true});
                    }
                }
            }
        }
    }catch (e){
        console.log(e)
    }
    return Promise.resolve(null);
}

export const creditAssetOnPaymentConfirmation = async (receivePayment) => {
    try{
        const newData = receivePayment.data;
        if((newData.completed === true && newData.paid_through === 'MTW') || (newData.completed === true && newData.overrideConfirmation === true)){
            const user = await users_store.doc(newData.user).get();
            if (user.exists) {
                const payment_crypto_id = newData.crypto;
                const curr_user_assets = user.data().assets;
                // eslint-disable-next-line no-prototype-builtins
                if (curr_user_assets.hasOwnProperty(payment_crypto_id)){
                    if(typeof curr_user_assets[payment_crypto_id] === 'number'){
                        curr_user_assets[payment_crypto_id] += newData.crypto_value;
                    }else if(!isNaN(Number.parseFloat(curr_user_assets[payment_crypto_id]))){
                        curr_user_assets[payment_crypto_id] = Number.parseFloat((Number.parseFloat(curr_user_assets[payment_crypto_id]) + newData.crypto_value).toFixed(4));
                    }else{
                        curr_user_assets[payment_crypto_id] = newData.crypto_value;
                    }
                } else {
                    curr_user_assets[payment_crypto_id] = newData.crypto_value;
                }
                await users_store.doc(newData.user).update({
                    assets: curr_user_assets,
                });
                if (user.data().referred){
                    await new UserRepository().creditReferralBonus(
                        user.data().referred_by, payment_crypto_id, newData.crypto_value, user.id
                    );
                }
                const _user = {id: user.id, data: user.data()};
                const subject = "Deposit Confirmed";
                const templateID = 3260295;
                const variables = {
                    "email": user.data().email,
                    "name": user.data().name,
                    "amount": newData.crypto_value,
                    "cryptoId": payment_crypto_id,
                };

                const response = (await globalObjects.MWInstance.post('send-mail', {
                    user: _user,
                    subject,
                    templateID,
                    variables
                })).data;
                if(response.status){
                    return null;
                }
            }
        }
    }catch (e){
        console.log(e);
    }
    return Promise.resolve(null);
}


export const deductAssetOnWithdrawalConfirmation = async (sendPayment) => {
    const newData = sendPayment.data;
    if(newData.status === 'CONFIRMED'){
        try{
            const user = await users_store.doc(newData.user).get();
            if (user.exists) {
                const payment_crypto_id = newData.crypto;
                const curr_user_assets = user.data().assets;
                // eslint-disable-next-line no-prototype-builtins
                if (curr_user_assets.hasOwnProperty(payment_crypto_id)){
                    curr_user_assets[payment_crypto_id] = Math.max(0, (curr_user_assets[payment_crypto_id]-newData.crypto_value));
                    await users_store.doc(newData.user).update({
                        assets: curr_user_assets,
                    });
                    const _user = {id: user.id, data: user.data()};
                    const subject = "Withdrawal Confirmed";
                    const templateID = 3260269;
                    const variables = {
                        "email": user.data().email,
                        "name": user.data().name,
                        "amount": newData.crypto_value,
                        "new_amount": curr_user_assets[payment_crypto_id],
                        "cryptoId": payment_crypto_id,
                    };

                    const response = (await globalObjects.MWInstance.post('send-mail', {
                        user: _user,
                        subject,
                        templateID,
                        variables
                    })).data;
                    if(response.status){
                        return null;
                    }
                }
            }
        }catch(e){
            console.error("Unable to deduct crypto asset:", e);
        }
    }
    return null;
}


export const sendWelcomeEmail = async (user) => {
    try{
        const _user = {id: user.id, data: user.data};
        const subject = "Welcome to RelaiEarn";
        const templateID = 3260219;
        const variables = {
            "email": user.data.email,
            "confirmation_link": 'https://relaiearn.com/',
            "name": user.data.name,
        };

        const response = (await globalObjects.MWInstance.post('send-mail', {
            user: _user,
            subject,
            templateID,
            variables
        })).data;
        if(response.status){
            return null;
        }
        // SMS Welcome Message
        // if(snapshot.data().phone){
        //   const status = await mailjetSMS
        //       .post('sms-send', {'version': 'v4'})
        //       .request({
        //         From: 'MTWallet',
        //         To: snapshot.data().phone,
        //         Text: `Welcome ${snapshot.data().name}, your Metrics Wallet account is now ready for you to buy, sell, store, and exchange crypto `,
        //       });
        //   functions.logger.log('<--------Welcome SMS Sent Summary -------->');
        //   functions.logger.info(JSON.stringify(status));
        //   functions.logger.log('<--------End of Summary -------->');
        // }
    }catch (e){
        console.log('Unable to send welcome email', e);
    }
    return Promise.resolve(null);
}
