import Payment from "./payment";

class SendPayment extends Payment{
    constructor() {
        super();
        this.data = Object.assign(this._data, new Model())
    }

}

function Model(){
    this.status = 'PENDING';
}

export default SendPayment