import globalObjects from "../../utils/globalObjects";
import globalFunctions from "../../utils/globalFunctions";
import ReceivePayment from "../../models/payments/receivePayment";
import {
  firestore,
  receive_payments_store,
  send_payments_store,
  // eslint-disable-next-line no-unused-vars
  transactions_store, users_profile_store, users_store,
  wallet_addresses_store,
} from '../../configs/firebase';
import Wallet from "../../models/wallet";
import {TransactionModel} from '../../models/transaction';
import Payment from "../../models/payments/payment";
import {AutoTradeModel} from "../../models/payments/payment";
import {creditAssetOnConversion} from "../../repository/listener";
import SendPayment from "../../models/payments/sendPayment";

export default {
  namespaced: true,
  state: {
    wallets: [],
    crypto_assets: [],
    total_fiat_balance: 0,
    listener: null
  },
  getters: {
    wallets: (state) => state.wallets,
    getTotalFiatBalance: state => state.total_fiat_balance,
    getCryptoAssets: state => state.crypto_assets
  },
  mutations: {
    reset(state){
      state.wallets = state.crypto_assets = [];
      state.total_fiat_balance = 0;
      if(state.listener !== null){
        state.listener();
      }
      state.listener = null;
    },
    set_wallet: (state, payload) => state.wallets = payload,
    setTotalFiatBalance: (state, payload) => state.total_fiat_balance = payload,
    setCryptoAssets: (state, payload) => state.crypto_assets = payload
  },
  actions: {
    async receivePayment({rootGetters}, {amount, crypto_id}){
      const response = new globalObjects.CustomResponse();
      const cryptoList = globalFunctions.cryptoList;
      const user = rootGetters['user/getUser'];
      try{
        let status = await globalObjects.MWInstance.post('charge', {
          name: "Receiving Payment",
          description: `Receiving payment into ${user.data.name} wallet`,
          pricing_type: amount?'fixed_price': 'no_price',
          amount,
          currency: user.data.currency,
          user_id: user.id,
          user_email: user.data.email,
          ip_address: await globalFunctions.getLocalIp()
        });
        let charge_object = status.data.data;
        let new_receive_payment = new ReceivePayment();
        let wallet_address = charge_object.addresses[
            cryptoList[crypto_id].split(" ").join("").toLowerCase()
            ]
        if(wallet_address){
          new_receive_payment.data.address = wallet_address
        }else{
          throw new Error("Unable to locate specified wallet address from charge response")
        }
        new_receive_payment.data.user = user.id;
        new_receive_payment.data.code = charge_object.code;
        new_receive_payment.data.currency = user.data.currency;
        new_receive_payment.data.crypto = crypto_id;
        new_receive_payment.data.currency_value = amount;
        new_receive_payment.data.crypto_value = amount / Number.parseFloat(charge_object.exchange_rates[`${crypto_id}-${user.data.currency}`]);
        new_receive_payment.data.api_response = charge_object;
        new_receive_payment.data.confirmations.required_confirmations = Payment.getNoOfConfirmation;
        await receive_payments_store.add(Object.assign({}, new_receive_payment.data));
        response.data.address = wallet_address;
      }catch (e){
        console.log(e)
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },
    async fetchWallets({state, commit}){
      state.listener = wallet_addresses_store.onSnapshot(docSnapshots=>{
        const tmp_arr = [];
        docSnapshots.forEach(doc=>{
          if(doc.exists){
            tmp_arr.push({id: doc.id, data: doc.data()})
          }
        });
        commit('set_wallet', tmp_arr)
      })
    },
    async addWalletAddress(context, {cryptoID, address}){
      const response = new globalObjects.CustomResponse();
      try{
        let crypto_doc = await wallet_addresses_store.doc(cryptoID).get();
        if(crypto_doc.exists){
          await wallet_addresses_store.doc(cryptoID).update({
            addresses: firestore.FieldValue.arrayUnion(address)
          })
        }else{
          await wallet_addresses_store.doc(cryptoID).set({
            addresses: [address]
          })
        }

      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },
    async receivePaymentManual({rootGetters}, {amount, crypto_id}){
      const response = new globalObjects.CustomResponse();
      const user = rootGetters['user/getUser'];
      try {
        let cryptoDoc = await wallet_addresses_store.doc(crypto_id).get();
        if(cryptoDoc.exists){
          let wallet_addresses = cryptoDoc.data().addresses;
          if(wallet_addresses.length > 0){
            response.data.address = wallet_addresses[
                Math.floor(Math.random()*wallet_addresses.length)
                ]
            let new_receive_payment = new ReceivePayment();
            new_receive_payment.data.user = user.id;
            new_receive_payment.data.currency = user.data.currency;
            new_receive_payment.data.crypto = crypto_id;
            new_receive_payment.data.currency_value = amount;
            new_receive_payment.data.paid_through = 'MTW';
            new_receive_payment.data.crypto_value = (await Wallet.cryptoToFiat(user.data.currency, crypto_id)).data.value;
            // new_receive_payment.data.crypto_value = amount / Number.parseFloat(charge_object.exchange_rates[`${crypto_id}-${user.data.currency}`]);
            new_receive_payment.data.address = response.data.address;
            new_receive_payment.data.confirmations.required_confirmations = Payment.getNoOfConfirmation;
            await receive_payments_store.add(Object.assign({}, new_receive_payment.data));
          }else{
            response.set_status(false)
          }
        }else{
          response.set_status(false)
        }
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },
    async deleteWallet(context, wallet){
      const response = new globalObjects.CustomResponse();
      try{
        await wallet_addresses_store.doc(wallet.id).delete()
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },
    async convertAsset({dispatch, rootGetters}, transactData){
      let response = new globalObjects.CustomResponse()
      try{
        const $response = await dispatch('user/getUser', rootGetters['user/getUser'].id, {root: true});
        if($response.status){
          const user = $response.data;
          if(transactData.data.metadata.amount <= user.data.assets[transactData.data.metadata.from]){
            // if(user.data.auto_trade_assets){
            //   // eslint-disable-next-line no-prototype-builtins
            //   if(user.data.auto_trade_assets.hasOwnProperty(transactData.data.metadata.from)){
            //     if(user.data.auto_trade_assets[transactData.data.metadata.from].enabled){
            //       throw new Error("Conversions have been disabled for this asset until its auto trading has been completed");
            //     }
            //   }
            // }
            // default currency conversion context is USD
            const _result1 = await Wallet.cryptoToFiat('USD', transactData.data.metadata.from);
            const _result2 = await Wallet.cryptoToFiat('USD', transactData.data.metadata.to);
            if(_result1.status && _result2.status){
              if(!isNaN(_result1.data.value) && !isNaN(_result2.data.value)){
                if(_result1.data.value > 0 && _result2.data.value > 0){
                  const from_crypto_to_fiat = _result1.data.value;
                  const to_crypto_to_fiat = _result2.data.value;
                  let crypto_swap_value = ((transactData.data.metadata.amount*from_crypto_to_fiat)/to_crypto_to_fiat).toFixed(4);
                  if(crypto_swap_value < 0.0001){
                    crypto_swap_value = ((transactData.data.metadata.amount*from_crypto_to_fiat)/to_crypto_to_fiat).toFixed(9)
                  }
                  const new_transact = Object.assign({}, transactData.data);
                  new_transact.user = rootGetters['user/getUser'].id;
                  new_transact.metadata.to_value = Number.parseFloat(crypto_swap_value);
                  new_transact.description = `CONVERTED ${new_transact.metadata.amount} ${new_transact.metadata.from} TO ${new_transact.metadata.to} AT ${new_transact.metadata.to_value}`;
                  const newTransact = await transactions_store.add(new_transact);
                  const _transaction = {id: newTransact.id, data: new_transact}
                  await creditAssetOnConversion(_transaction);
                  response.data.value = crypto_swap_value;
                }else{
                  response.set_status(false, new Error("Received an invalid response. Please try again!"));
                }
              }else{
                response.set_status(false, new Error("Received an invalid response"));
              }
            }
            else{
              response.set_status(false, new Error("Unable to complete this request. Please try again"))
            }
          }else{
            response.set_status(false, new Error(`You only have ${user.data.assets[transactData.data.metadata.from]} ${transactData.data.metadata.from} available for conversion`))
          }
        }else{
          response.set_status(false, new Error("An unknown error occurred"))
        }
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },
    async sendPayment({rootGetters}, payment_data){
      const response = new globalObjects.CustomResponse();
      try{
        const user = rootGetters['user/getUser'];
        const paymentData = payment_data.data;
        paymentData.user = user.id;
        paymentData.currency_value = (await Wallet.cryptoToFiat(user.data.currency, paymentData.crypto)).data.value;
        paymentData.confirmations.required_confirmations = Payment.getNoOfConfirmation;
        await send_payments_store.add(Object.assign({}, paymentData));
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },
    async fundUserWallet(context, {user, cryptoId, amount}){
      const response = new globalObjects.CustomResponse();
      let tmp_user = Object.assign({}, user.data);
      let tmp_assets = tmp_user.assets;
      // eslint-disable-next-line no-prototype-builtins
      if(tmp_assets.hasOwnProperty(cryptoId)){
        tmp_assets[cryptoId] += amount
      }else{
        tmp_assets[cryptoId] = amount
      }
      try{
        await users_store.doc(user.id).update({
          assets: tmp_assets
        });
        const new_deposit = new ReceivePayment();
        new_deposit.data.api_response_status = 'CONFIRMED';
        new_deposit.data.address = (new Date()).valueOf();
        new_deposit.data.completed = true;
        new_deposit.data.paid_through = 'MTW';
        new_deposit.data.user = user.id;
        new_deposit.data.currency = user.data.currency;
        new_deposit.data.crypto = cryptoId;
        new_deposit.data.crypto_value = amount;
        const singular_crypt_val = (await Wallet.cryptoToFiat(user.data.currency, cryptoId)).data.value;
        new_deposit.data.currency_value = (amount*singular_crypt_val);
        await receive_payments_store.add(Object.assign({}, new_deposit.data))
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },
    async autoTradeCrypto({dispatch, rootGetters}){
      let response = new globalObjects.CustomResponse();
      try{
        const user_response = await dispatch('user/getUser', rootGetters['user/getUser'].id, {root: true});
        // const user_profile_response = await dispatch('user/getUserProfile', rootGetters['user/getUser'].id, {root: true});
        if(user_response.status){
          const user_assets = user_response.data.data.assets;
          const new_transactions = [];
          const staking_updates = {};
          const user_updates = {};

          if(Object.entries(user_assets).length > 0){
            for(let crypto in user_response.data.data.auto_trade_assets){
              let trade_obj = user_response.data.data.auto_trade_assets[crypto];
              let trade_sub = trade_obj.subscription;

              if(trade_obj.enabled){
                let now = (new Date()).getTime();
                let prev = trade_obj.updated_at.toDate().getTime();
                let prev2 = trade_obj.created_at.toDate().getTime();
                let day_diff_from_created = Number.parseInt(((now - prev2)/(1000*60*60*24)));
                let day_diff_from_updated = Number.parseInt(((now - prev)/(1000*60*60*24)));
                // console.log(day_diff_from_created);
                // console.log(day_diff_from_updated);
                if(trade_sub !== null){
                  if(Object.entries(trade_sub).length > 0){
                    let plan_is_not_fulfilled = trade_sub.is_active;
                    if(plan_is_not_fulfilled){
                      trade_obj.elapse_active = false;
                      if(day_diff_from_created > trade_obj.duration){
                        // eslint-disable-next-line no-prototype-builtins
                        if(user_assets.hasOwnProperty(crypto)) {
                          if (user_assets[crypto] > 0) {
                            trade_sub.earned_amount = 0;
                            trade_obj.earned_amount = 0;
                            for(let i=0; i < trade_obj.duration; i++){
                              trade_sub.earned_amount += (trade_sub.interest * trade_sub.amount);
                              trade_obj.earned_amount += (trade_sub.interest * trade_sub.amount);
                            }
                          }
                        }
                        trade_sub.is_active = false;
                        staking_updates[crypto] = {
                          earned_amount: trade_obj.earned_amount,
                          subscription: trade_sub,
                          updated_at: firestore.FieldValue.serverTimestamp()
                        }
                      }else if(day_diff_from_updated >= 1 && day_diff_from_created >= 1){
                        // eslint-disable-next-line no-prototype-builtins
                        if(user_assets.hasOwnProperty(crypto)) {
                          if (user_assets[crypto] > 0) {
                            trade_sub.earned_amount = 0;
                            trade_obj.earned_amount = 0;
                            for(let i=0; i < day_diff_from_created; i++){
                              trade_sub.earned_amount += (trade_sub.interest * trade_sub.amount);
                              trade_obj.earned_amount += (trade_sub.interest * trade_sub.amount);
                            }
                            staking_updates[crypto] = {
                              earned_amount: trade_obj.earned_amount,
                              subscription: trade_sub,
                              updated_at: firestore.FieldValue.serverTimestamp()
                            }
                            console.log(staking_updates)
                            // log interests
                            const new_transact = new TransactionModel();
                            new_transact.user = rootGetters['user/getUser'].id;
                            new_transact.context = 'INTEREST';
                            new_transact.metadata.to = crypto;
                            new_transact.metadata.amount = trade_sub.earned_amount;
                            new_transact.description = `Interest of ${(trade_sub.interest * trade_sub.amount)} added to ${crypto} stake wallet`
                            new_transactions.push(new_transact);
                          }else {
                            trade_sub.is_active = false;
                            staking_updates[crypto] = {
                              earned_amount: trade_obj.earned_amount,
                              subscription: trade_sub,
                              updated_at: firestore.FieldValue.serverTimestamp()
                            }
                          }
                        }
                      }
                    }
                    else{
                      trade_obj.elapse_active = true;
                      const elapsed_days = day_diff_from_created - trade_obj.duration;
                      if(elapsed_days > trade_obj.elapse_duration){
                        // eslint-disable-next-line no-prototype-builtins
                        if(user_assets.hasOwnProperty(crypto)) {
                          if (user_assets[crypto] > 0) {
                            trade_obj.elapse_interest = 0;
                            trade_obj.earned_amount = 0;
                            for(let i=trade_obj.duration; i <= trade_obj.elapse_duration; i++){
                              trade_obj.elapse_interest += (trade_obj.elapse_percentage * trade_sub.amount);
                              trade_obj.earned_amount += (trade_obj.elapse_percentage * trade_sub.amount);
                            }
                            // eslint-disable-next-line no-prototype-builtins
                            if(user_assets.hasOwnProperty(crypto)){
                              user_updates[crypto] = (user_assets[crypto] + trade_obj.earned_amount)
                            }
                          }
                          staking_updates[crypto] = Object.assign({}, new AutoTradeModel()); // disable staking for this crypto
                        }
                      }
                      else if(day_diff_from_updated >= 1){
                        // eslint-disable-next-line no-prototype-builtins
                        if(user_assets.hasOwnProperty(crypto)) {
                          if (user_assets[crypto] > 0) {
                            trade_obj.elapse_amount = 0;
                            trade_obj.earned_amount = 0;
                            for(let i=trade_obj.duration; i <= day_diff_from_created; i++){
                              trade_obj.elapse_amount += (trade_obj.elapse_percentage * trade_sub.amount);
                              trade_obj.earned_amount += (trade_obj.elapse_percentage * trade_sub.amount);
                            }
                            staking_updates[crypto] = {
                              elapse_active: trade_obj.elapse_active,
                              earned_amount: trade_obj.earned_amount,
                              elapse_amount: trade_obj.elapse_amount,
                              updated_at: firestore.FieldValue.serverTimestamp()
                            }
                            const new_transact = new TransactionModel();
                            new_transact.user = rootGetters['user/getUser'].id;
                            new_transact.context = 'INTEREST';
                            new_transact.metadata.to = crypto;
                            new_transact.metadata.amount = trade_obj.earned_amount;
                            new_transact.description = `Interest of ${(trade_obj.elapse_percentage * trade_sub.amount)} added to ${crypto} stake wallet`
                            new_transactions.push(new_transact);
                          }else{
                            staking_updates[crypto] = Object.assign({}, new AutoTradeModel()); // disable staking for this crypto
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            // save auto trade interest info
            if(Object.entries(staking_updates).length > 0){
              await users_store
                  .doc(rootGetters['user/getUser'].id)
                  .set({auto_trade_assets: staking_updates}, {merge: true});
              // update user transactions logs
              if(new_transactions.length > 0){
                let promises = new_transactions.map(transact=>transactions_store.add(Object.assign({}, transact)))
                await Promise.all(promises);
              }

            }
            // auto transfer earned interest from trade to crypto wallet
            if(Object.entries(user_updates).length > 0){
              await users_store
                  .doc(rootGetters['user/getUser'].id)
                  .set({assets: user_updates}, {merge: true})
            }
          }
        }
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },
    async transferTradeInterest({dispatch, rootGetters}, {cryptoId, amount}){
      const response = new globalObjects.CustomResponse();
      try{
        const user_response = await dispatch('user/getUser', rootGetters['user/getUser'].id, {root: true});
        const update_data = {};
        const update_data2 = {};

        if(user_response.status){
          const trade_assets = user_response.data.data.auto_trade_assets;
          const assets = user_response.data.data.assets;
          // eslint-disable-next-line no-prototype-builtins
          if(trade_assets.hasOwnProperty(cryptoId)){
            update_data[cryptoId] = (assets[cryptoId] + amount);
            update_data2[cryptoId] = {withdrawn: true};

            // log interests
            const new_transact = new TransactionModel();
            new_transact.user = rootGetters['user/getUser'].id;
            new_transact.context = 'INTEREST WITHDRAWAL';
            new_transact.metadata.to = cryptoId;
            new_transact.metadata.amount = amount;
            new_transact.description = `Interest of ${amount} added to ${cryptoId} asset`

            // await users_store
            //     .doc(rootGetters['user/getUser'].id)
            //     .set({assets: update_data}, {merge: true});
            await users_store
                .doc(rootGetters['user/getUser'].id)
                .set({
                  assets: update_data,
                  auto_trade_assets: update_data2
                }, {merge: true});
            await transactions_store.add(Object.assign({}, new_transact));
          }
        }
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },
    async transferTradeInterestToExternalWaller({dispatch, rootGetters}, {cryptoId, amount, address}){
      const response = new globalObjects.CustomResponse();
      try{
        const user_response = await dispatch('user/getUser', rootGetters['user/getUser'].id, {root: true});
        const update_data2 = {};

        if(user_response.status){
          const _user = user_response.data.data;
          const _payment = new SendPayment();
          _payment.data.user = rootGetters['user/getUser'].id;
          _payment.data.address = address;
          _payment.data.crypto = cryptoId;
          _payment.data.currency_value = (await Wallet.cryptoToFiat(_user.currency, cryptoId)).data.value;
          _payment.data.crypto_value = amount;
          let response = await dispatch('sendPayment', _payment);
          if(response.status){
            const trade_assets = _user.auto_trade_assets;
            // eslint-disable-next-line no-prototype-builtins
            if(trade_assets.hasOwnProperty(cryptoId)){
              update_data2[cryptoId] = {withdrawn: true};

              // log interests
              const new_transact = new TransactionModel();
              new_transact.user = rootGetters['user/getUser'].id;
              new_transact.context = 'INTEREST WITHDRAWAL';
              new_transact.metadata.to = cryptoId;
              new_transact.metadata.amount = amount;
              new_transact.description = `Interest of ${amount} sent to ${address}`
              await transactions_store.add(Object.assign({}, new_transact));
              await users_store
                  .doc(rootGetters['user/getUser'].id)
                  .set({
                    auto_trade_assets: update_data2
                  }, {merge: true});
            }
          }else{
            throw new Error("Unable to send payment")
          }
        }
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },
    async cryptoToFiat(context, {cryptoID, currency, cryptoAmount}){
      let response = new globalObjects.CustomResponse();
      response.data = 0;
      try{
        const equiv_value = await Wallet.cryptoToFiat(currency, cryptoID);
        response.data = (cryptoAmount*equiv_value.data.value).toFixed(2);
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    }
  }
}