<template>
    <div class="modal fade dialogbox" id="postLogout" data-bs-backdrop="static" tabindex="-1"
         role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-icon text-success">
                    <ion-icon name="log-out-outline"></ion-icon>                </div>
                <div class="modal-header">
                    <h5 class="modal-title">Before you leave</h5>
                </div>
                <div class="modal-body">
                    <p>
                        You will need your wallet key phrases to recover your digital assets if you are unable to
                        remember your password after you log out
                    </p>
                    <p>
                        You can have your passphrases delivered to your inbox by clicking on the
                        <span class="badge badge-primary">recover passphrase</span> option on the settings page
                    </p>
                </div>
                <div class="modal-footer">
                    <div class="btn-inline">
                        <a @click="$store.dispatch('auth/logout')" data-bs-dismiss="modal" href="javascript:void(0)" class="btn btn-outline-primary">Logout</a>
                        <a href="javascript:void(0)" class="btn" data-bs-dismiss="modal">Close</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'postLogout',
};
</script>

<style scoped>

</style>