import {firestore} from "../configs/firebase";

class Transaction{
    constructor() {
        this.data = new Model()
    }

}

function Model(){
    this.user = ''; // user-id
    this.context = 'CONVERT';  // CONVERT,PROFIT, INTEREST
    this.metadata = {
        from: '', to: 'BTC',
        amount: 0,
        to_value: 0
    };
    this.created_at = firestore.FieldValue.serverTimestamp();
    this.description = ''
}

export const TransactionModel = Model;
export default Transaction