import {
    firestore,
    users_profile_store,
    users_store,
} from '../../configs/firebase';
import globalObjects from "../../utils/globalObjects";
import GlobalFunctions from "../../utils/globalFunctions";
import firebase from "../../configs/firebase";
import Wallet from '../../models/wallet';
import {AutoTradeModel} from "../../models/payments/payment";

export default {
    namespaced: true,
    state: {
        user: {id: '', data: {}},
        user_profile: {id: '', data: {}},
        all_users: [],
        listener: null,
        all_users_listener: null,
        profile_listener: null
    },
    getters: {
        getUser: (state)=> state.user,
        getAllUsers: (state)=> state.all_users,
        getProfile: (state) => state.user_profile,
        getUserFiatSymbol: (state) => {
            if(state.user.data.currency){
                return GlobalFunctions.fiats[state.user.data.currency].symbol_native;
            }
            return ''
        }
    },
    mutations: {
        setUser: (state, payload) => state.user = payload,
        setAllUsers: (state, payload) => state.all_users = payload,
        setUserProfile: (state, payload) => state.user_profile = payload,
        reset: (state) => {
            if(state.listener !== null){
                state.listener()
            }
            if(state.profile_listener !== null){
                state.profile_listener();
            }
            if(state.all_users_listener !== null){
                state.all_users_listener();
            }
            state.listener = state.profile_listener = state.all_users_listener = null;
            state.user = state.user_profile = {id: '', data: {}}
        }
    },
    actions: {
        async getUser(context, user_id){
            const response = new globalObjects.CustomResponse();
            try{
                let userDoc = await users_store.doc(user_id).get();
                if(userDoc.exists){
                    response.data = {
                        id: userDoc.id,
                        data: userDoc.data()
                    }
                }else{
                    response.set_status(false)
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async getUserByEmail(context, email){
            const response = new globalObjects.CustomResponse();
            try{
                let userDoc = await users_store
                .where('email', '==', email)
                .limit(1)
                .get();
                if(!userDoc.empty){
                    response.data = {
                        id: userDoc.docs[0].id,
                        data: userDoc.docs[0].data()
                    }
                }else{
                    response.set_status(false)
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async getUserProfile(context, user_id){
            const response = new globalObjects.CustomResponse();
            try{
                let profileDoc = await users_profile_store.doc(user_id).get();
                if(profileDoc.exists){
                    response.data = {
                        id: profileDoc.id,
                        data: profileDoc.data()
                    }
                }else{
                    response.set_status(false)
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async fetchAllUsers({state, commit}){
            state.all_users_listener = users_store
            .orderBy('created_at', 'desc')
            .onSnapshot(snapshot => {
                let tmp_arr = [];
                snapshot.forEach(doc=>{
                    if(doc.exists){
                        tmp_arr.push({
                            id: doc.id,
                            data: doc.data()
                        })
                    }
                });
                commit('setAllUsers', tmp_arr)
            })
        },
        async initUser({state, commit}, {user_id, profile_id}){
            state.listener = users_store.doc(user_id).onSnapshot(doc=>{
                if(doc.exists){
                    commit('setUser', {
                        id: doc.id,
                        data: doc.data()
                    })
                }
            });
            state.profile_listener = users_profile_store.doc(profile_id).onSnapshot(doc=>{
                if(doc.exists){
                    commit('setUserProfile', {
                        id: doc.id,
                        data: doc.data()
                    })
                }
            });
            return Promise.resolve(null)
        },
        async updateUser(context, {userId, userData}){
            const response = new globalObjects.CustomResponse();
            try{
                delete userData.created_at;
                userData.updated_at = firestore.FieldValue.serverTimestamp();
                await users_store.doc(userId).update(Object.assign({}, userData))
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async updateUserProfile(context, {userId, userData}){
            const response = new globalObjects.CustomResponse();
            try{
                await users_profile_store
                .doc(userId)
                .update(Object.assign({}, userData))
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async enableAutoTrade({dispatch, rootGetters}, {cryptoId, duration, e_duration, e_percentage_val, percentage_val}){
            const response = new globalObjects.CustomResponse();
            try{
                const user_response = await dispatch('user/getUser', rootGetters['user/getUser'].id, {root: true});
                if(user_response.status){
                    const user_assets = user_response.data.data.assets;
                    const trade_assets = user_response.data.data.auto_trade_assets;
                    if(Object.entries(user_assets).length > 0){
                        // eslint-disable-next-line no-prototype-builtins
                        if(user_assets.hasOwnProperty(cryptoId)){
                            if(Object.entries(trade_assets).length > 0){
                                // eslint-disable-next-line no-prototype-builtins
                                if(trade_assets.hasOwnProperty(cryptoId)){
                                    if(trade_assets[cryptoId].enabled){
                                       throw new Error("Unknown operation");
                                    }
                                }
                            }
                            let usd_value = await Wallet.cryptoToFiat('USD', cryptoId);
                            if(!isNaN(usd_value.data.value)){
                                usd_value = (usd_value.data.value*user_assets[cryptoId]);
                                let percentage_value = Wallet.getTradeInterest(usd_value);
                                if(percentage_value > 0){
                                    let update_data = {};
                                    let trade_model = new AutoTradeModel();
                                    trade_model.subscription = AutoTradeModel.subscriptionModel;
                                    trade_model.enabled = true;
                                    trade_model.subscription.is_active = true;
                                    trade_model.subscription.interest = percentage_value;
                                    // trade_model.subscription.plan_amount = planAmount;
                                    trade_model.subscription.amount = user_assets[cryptoId];
                                    trade_model.duration = duration;
                                    trade_model.elapse_duration = e_duration;
                                    trade_model.elapse_percentage = e_percentage_val
                                    trade_model.subscription.plan_percentage = percentage_val
                                    update_data[cryptoId] = Object.assign({}, trade_model)
                                    await users_store
                                    .doc(rootGetters['user/getUser'].id)
                                    .set({ auto_trade_assets: update_data}, {merge: true})
                                }else{
                                    response.set_status(false, new Error("Your crypto asset is not past the minimum threshold to enable this feature"))
                                }
                            }
                            else{
                                response.set_status(false, new Error("An Unknown error occurred. Contact the support team!"))
                            }
                        }else{
                            response.set_status(false, new Error("Your crypto asset is not past the minimum threshold for enabling this feature"))
                        }
                    }
                    else{
                        response.set_status(false, new Error("Credit your wallet to enable this feature!"))
                    }
                }
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        },
        async updateUserPassword(context, password){
            const response = new globalObjects.CustomResponse();
            try{
                const user = firebase.auth().currentUser;
                await user.updatePassword(password)
            }catch (e){
                response.set_status(false, e)
            }
            return Promise.resolve(response)
        }
    }
}