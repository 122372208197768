import axios from "axios";

const error_messages = {
    'auth/email-already-in-use': "The email is already in use by another",
    'auth/invalid-email': "The email is not a valid email address",
    'auth/operation-not-allowed': "Unknown Error. Contact the System Admin",    // Thrown if email/password accounts are not enabled
    'auth/weak-password': 'The password is too weak',
    'cancelled': 'Operation cancelled',
    'unavailable': 'The service is currently not available',
    'unauthenticated': "Not enough valid credentials to continue operation",
    "auth/user-disabled": "You have been disabled. Contact the System Admin",
    "auth/user-not-found": "This account does not exist!",
    "auth/wrong-password": "Invalid email or password"
}
class CustomResponse{
    constructor() {
        this.status = true;
        this._error = null;
        this.data = {};
    }

    get message(){
        return error_messages[this._error.code] || this._error.message || this._error
    }
    set_status(status, error=null){
        this.status = status;
        this._error = error
    }
}
const LOCAL_DEV_API_URL = 'http://localhost:3001/';
const LIVE_API_URL = 'https://relaiearn-backend.onrender.com';
let API_URL;
const host = window.location.host;

switch (host){
    case 'reliaearn-f2293.web.app': {
        API_URL = LIVE_API_URL;
        break;
    }
    case "localhost:8080": {
        API_URL = LOCAL_DEV_API_URL;
        break;
    }
    case "www.earnrelai.com": {
        API_URL = LIVE_API_URL;
        break;
    }
    case "earnrelai.com": {
        API_URL = LIVE_API_URL;
        break;
    }
    case "earn-relai.com": {
        API_URL = LIVE_API_URL;
        break;
    }
}

// TODO: update API URL to production URL
const MWInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Authorization': process.env.VUE_APP_API_KEY,
        'Access-Control-Allow-Origin': '*',
    },
});
const NOMICSInstance = axios.create({
    baseURL: 'https://api.nomics.com/v1/',
    headers: {
        'Access-Control-Allow-Origin': '*',
    },
})
export default {
    CustomResponse,
    MWInstance,
    NOMICSInstance
}