<template>
    <div class="modal fade dialogbox" id="emailSpam" data-bs-backdrop="static" tabindex="-1"
         role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-icon text-success">
                    <ion-icon name="mail-open-outline"></ion-icon>
                </div>
                <div class="modal-header">
                    <h5 class="modal-title">We are working on it</h5>
                </div>
                <div class="modal-body">
                    <p>
                        We are not in control of how and where the emails we send might end up
                    </p>
                    <p>
                        We encourage all users to periodically check their spam, promotional and category inbox and mark messages received
                        from Metrics Wallet as <span class="badge badge-primary">not spam</span>
                    </p>
                    <p>Our sincere apologies 😢</p>
                </div>
                <div class="modal-footer">
                    <div class="btn-inline">
                        <a href="javascript:void(0)" class="btn" data-bs-dismiss="modal">Close</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'emailSpam',
};
</script>

<style scoped>

</style>