import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Notification from "./components/Notification";
import emailSpam from './components/modals/emailSpam';
import postLogout from './components/modals/postLogout';
// import cryptoImg from './components/cryptoImg';
import firebase from "./configs/firebase";
import VueQrCode from "vue-qrcode";
import VueApexCharts from 'vue-apexcharts'
import VueCountryCode from "vue-country-code";

// Plugins
import {FunctionPlugins, ModelsPlugin} from "./utils/plugins";


Vue.config.productionTip = false
Vue.config.ignoredElements = ['ion-icon']

Vue.component('Notification', Notification)
Vue.component('SpamNoti', emailSpam)
Vue.component('postLogout', postLogout)

Vue.use(FunctionPlugins)
Vue.use(ModelsPlugin)
Vue.component('qrCode', VueQrCode);
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
// Vue.component('CryptoImg', cryptoImg)
Vue.use(VueCountryCode);


let app;
firebase.auth().onAuthStateChanged(function(){
  if(!app){
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})