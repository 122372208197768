import {
  firestore,
  receive_payments_store,
  send_payments_store,
  transactions_store,
  users_store
} from "../../configs/firebase";
import globalObjects from "../../utils/globalObjects";
import {
  creditAssetOnPaymentConfirmation,
  deductAssetOnWithdrawalConfirmation,
  reSubscribeStakingOnNewDeposit
} from "../../repository/listener";

export default {
  namespaced: true,
  state: {
    receivedPayments: [],
    sentPayments: [],
    swapPayments: [],
    allReceivedPayments: [],
    allSentPayments: [],
    allSwapPayments: [],
    received_listener: null,
    sent_listener: null,
    swap_listener: null,
    received_listener_all: null,
    sent_listener_all: null
  },
  getters: {
    getReceivedPayments: state => state.receivedPayments,
    getSentPayments: state => state.sentPayments,
    getSwapPayments: state => state.swapPayments,
    getAllReceivedPayments: state => state.allReceivedPayments,
    getAllSentPayments: state => state.allSentPayments,
    getAllSwapPayments: state => state.allSwapPayments,
  },
  mutations: {
    reset(state){
      if(state.received_listener !== null){
        state.received_listener()
      }
      if(state.sent_listener !== null){
        state.sent_listener()
      }
      if(state.swap_listener !== null){
        state.swap_listener()
      }
      if(state.received_listener_all !== null){
        state.received_listener_all()
      }
      if(state.sent_listener_all !== null){
        state.sent_listener_all()
      }
      state.received_listener = state.sent_listener = null;
      state.sent_listener_all = state.received_listener_all = null;
      state.swap_listener = null;
      state.receivedPayments = state.sentPayments = state.swapPayments = [];
      state.allReceivedPayments = state.allSentPayments = state.allSwapPayments = [];
    },
    setReceivedPayments: (state, payload)=> state.receivedPayments = payload,
    setSentPayments: (state, payload)=> state.sentPayments = payload,
    setSwapPayments: (state, payload)=>state.swapPayments=payload,
    setAllReceivedPayments: (state, payload)=> state.allReceivedPayments = payload,
    setAllSentPayments: (state, payload)=> state.allSentPayments = payload,
    setAllSwapPayments: (state, payload)=> state.allSwapPayments = payload,
  },
  actions: {
    fetchAllReceivedPayments({state, commit}, callback=null){
      state.received_listener_all = receive_payments_store
        .orderBy('created_at', 'desc')
        .onSnapshot(docSnapshots=>{
          const tmp_arr = [];
          docSnapshots.forEach(doc=>{
            tmp_arr.push({
              id: doc.id, data: doc.data()
            })
          });
          commit('setAllReceivedPayments', tmp_arr);
          if(callback) callback()
        })
    },
    fetchAllSentPayments({state, commit}){
      state.received_listener_all = send_payments_store
        .orderBy('created_at', 'desc')
        .onSnapshot(docSnapshots=>{
          const tmp_arr = [];
          docSnapshots.forEach(doc=>{
            tmp_arr.push({
              id: doc.id, data: doc.data()
            })
          });
          commit('setAllSentPayments', tmp_arr)
        })
    },
    fetchAllSwapPayments({state, commit}){
      state.received_listener_all = transactions_store
        .orderBy('created_at', 'desc')
        .onSnapshot(docSnapshots=>{
          const tmp_arr = [];
          docSnapshots.forEach(doc=>{
            tmp_arr.push({
              id: doc.id, data: doc.data()
            })
          });
          commit('setAllSwapPayments', tmp_arr)
        })
    },

    fetchReceivedPayments({state, commit, rootGetters}){
      const user = rootGetters['user/getUser'];
      state.received_listener = receive_payments_store
        .where('user', '==', user.id)
        .orderBy('created_at', 'desc')
        .onSnapshot(docSnapshots=>{
          const tmp_arr = [];
          docSnapshots.forEach(doc=>{
            tmp_arr.push({
              id: doc.id, data: doc.data()
            })
          });
          commit('setReceivedPayments', tmp_arr)
        })
    },
    fetchSentPayments({state, commit, rootGetters}){
      const user = rootGetters['user/getUser'];
      state.sent_listener = send_payments_store
        .where('user', '==', user.id)
        .orderBy('created_at', 'desc')
        .onSnapshot(docSnapshots=>{
          const tmp_arr = [];
          docSnapshots.forEach(doc=>{
            tmp_arr.push({
              id: doc.id, data: doc.data()
            })
          });
          commit('setSentPayments', tmp_arr)
        })
    },
    fetchSwapPayments({state, commit, rootGetters}){
      const user = rootGetters['user/getUser'];
      state.swap_listener = transactions_store
        .where('user', '==', user.id)
        .orderBy('created_at', 'desc')
        .onSnapshot(docSnapshots=>{
          const tmp_arr = [];
          docSnapshots.forEach(doc=>{
            tmp_arr.push({
              id: doc.id, data: doc.data()
            })
          });
          commit('setSwapPayments', tmp_arr)
        })
    },

    async confirmManualPayment(context, {payment, amount, fiat_amount}){
      const response = new globalObjects.CustomResponse();
      try{
        await receive_payments_store
          .doc(payment.id)
          .update({
            completed: true,
            api_response_status: 'CONFIRMED',
            crypto_value: amount,
            currency_value: fiat_amount,
            'confirmations.no_of_confirmations': payment.data.confirmations? (payment.data.confirmations.required_confirmations || 0) : 0
          });
        const userDoc = await users_store.doc(payment.data.user).get();
        if(userDoc.exists){
          await creditAssetOnPaymentConfirmation(payment)
          await reSubscribeStakingOnNewDeposit(
              {id: userDoc.id, data: userDoc.data()},
              payment.data.crypto
          )
        }
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },
    async overrideAutomaticPayment(context, {payment}){
      const response = new globalObjects.CustomResponse();
      try{
        await receive_payments_store
        .doc(payment.id)
        .update({
          completed: true,
          overrideConfirmation: true,
          api_response_status: 'CONFIRMED',
          'confirmations.no_of_confirmations': payment.data.confirmations? (payment.data.confirmations.required_confirmations || 0) : 0
          // crypto_value: amount,
          // currency_value: fiat_amount
        });
        const userDoc = await users_store.doc(payment.data.user).get();
        if(userDoc.exists){
          await creditAssetOnPaymentConfirmation(payment)
          await reSubscribeStakingOnNewDeposit(
              {id: userDoc.id, data: userDoc.data()},
              payment.data.crypto
          )
        }
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },
    async confirmWithdrawal(context, paymentId){
      const response = new globalObjects.CustomResponse();
      try{
        await send_payments_store
          .doc(paymentId)
          .update({
            status: 'CONFIRMED',
          });
        const sendPaymentDoc = await send_payments_store.doc(paymentId).get();
        if(sendPaymentDoc.exists){
          await deductAssetOnWithdrawalConfirmation({
            id: sendPaymentDoc.id,
            data: sendPaymentDoc.data()
          })
        }
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },

    async fetchReceivedPaymentsFiltered({rootGetters}, cryptoId){
      const user = rootGetters['user/getUser'];
      const response = new globalObjects.CustomResponse();
      response.data = [];
      try{
        const paymentDoc = await receive_payments_store
          .where('user', '==', user.id)
          .where('crypto', '==', cryptoId)
          .get();
        if(!paymentDoc.empty){
          paymentDoc.forEach(doc=>{
            if(doc.exists){
              response.data.push({
                id: doc.id,
                data: doc.data()
              })
            }
          })
        }
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },
    async fetchSentPaymentsFiltered({rootGetters}, cryptoId){
      const user = rootGetters['user/getUser'];
      const response = new globalObjects.CustomResponse();
      response.data = [];
      try{
        const paymentDoc = await send_payments_store
          .where('user', '==', user.id)
          .where('crypto', '==', cryptoId)
          .get();
        if(!paymentDoc.empty){
          paymentDoc.forEach(doc=>{
            if(doc.exists){
              response.data.push({
                id: doc.id,
                data: doc.data()
              })
            }
          })
        }
      }catch (e){
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },

    async handlePaymentConfirmations({rootGetters}){
      const response = new globalObjects.CustomResponse();
      try{
        const userId = rootGetters['user/getUser'].id;
        const sentPaymentsUpdates = [];
        const receivedPaymentsUpdates = [];
        const receivedPayments = await receive_payments_store
            .where('user', '==', userId)
            .where("api_response_status", "!=", "FAILED")
            // .where("api_response_status", "!=", "CONFIRMED")
            .get();
        const sentPayments = await send_payments_store
            .where('user', '==', userId)
            .where("status", "!=", "FAILED")
            // .where("status", "!=", "CONFIRMED")
            .get();
        receivedPayments.forEach(doc=>{
          if(doc.data().api_response_status !== "CONFIRMED"){
            if(doc.data().confirmations){
              let no_confirm = Number.parseInt(doc.data().confirmations.no_of_confirmations);
              const no_required = Number.parseInt(doc.data().confirmations.required_confirmations);
              let last_updated = doc.data().confirmations.updated_at;
              const now = (new Date()).getTime();
              if(!isNaN(no_confirm) && !isNaN(no_required) && last_updated){
                last_updated = last_updated.toDate().getTime();
                const hr_diff = Number.parseInt(((now - last_updated)/(1000*60*60)));
                if(hr_diff >= 1){
                  if(no_confirm < no_required){
                    for(let count = 0; count < hr_diff; count++){
                      if(no_confirm < no_required){
                        no_confirm++;
                      }
                    }
                    receivedPaymentsUpdates.push(receive_payments_store.doc(doc.id).update({
                      'confirmations.no_of_confirmations': no_confirm,
                      'confirmations.updated_at': firestore.FieldValue.serverTimestamp()
                    }))
                  }
                }
              }
            }
          }
          else if(doc.data().api_response_status === "CONFIRMED") {
            if (doc.data().confirmations) {
              let no_confirm = Number.parseInt(doc.data().confirmations.no_of_confirmations);
              const no_required = Number.parseInt(doc.data().confirmations.required_confirmations);
              if(no_confirm < no_required){
                receivedPaymentsUpdates.push(receive_payments_store.doc(doc.id).update({
                  'confirmations.no_of_confirmations': no_required,
                  'confirmations.updated_at': firestore.FieldValue.serverTimestamp()
                }))
              }
            }
          }
        });
        sentPayments.forEach(doc=>{
          if(doc.data().status !== "CONFIRMED"){
            if(doc.data().confirmations){
              let no_confirm = Number.parseInt(doc.data().no_of_confirmations);
              const no_required = Number.parseInt(doc.data().required_confirmations);
              let last_updated = doc.data().confirmations.updated_at;
              const now = (new Date()).getTime();
              if(!isNaN(no_confirm) && !isNaN(no_required)){
                last_updated = last_updated.toDate().getTime();
                const hr_diff = Number.parseInt(((now - last_updated)/(1000*60*60)));
                if(hr_diff >= 1){
                  if(no_confirm < no_required){
                    for(let count = 0; count < hr_diff; count++){
                      if(no_confirm < no_required){
                        no_confirm++;
                      }
                    }
                    sentPaymentsUpdates.push(send_payments_store.doc(doc.id).update({
                      'confirmations.no_of_confirmations': no_confirm,
                      'confirmations.updated_at': firestore.FieldValue.serverTimestamp()
                    }))
                  }
                }
              }
            }
          }
          else if(doc.data().api_response_status === "CONFIRMED") {
            if (doc.data().confirmations) {
              let no_confirm = Number.parseInt(doc.data().confirmations.no_of_confirmations);
              const no_required = Number.parseInt(doc.data().confirmations.required_confirmations);
              if(no_confirm < no_required){
                sentPaymentsUpdates.push(send_payments_store.doc(doc.id).update({
                  'confirmations.no_of_confirmations': no_required,
                  'confirmations.updated_at': firestore.FieldValue.serverTimestamp()
                }))
              }
            }
          }
        });

        if(Object.entries(receivedPaymentsUpdates).length > 0){
          await Promise.all(receivedPaymentsUpdates);
        }
        if(Object.entries(sentPaymentsUpdates).length > 0){
          await Promise.all(sentPaymentsUpdates);
        }

      }catch (e){
        console.log(e);
        response.set_status(false, e)
      }
      return Promise.resolve(response);
    }
  }
}