import Vue from 'vue'
import VueRouter from 'vue-router'
import Base from "../views/Base";
import store from "../store/index"
import firebase from "../configs/firebase";

Vue.use(VueRouter)

const routes = [
    // {
    //     path: '/',
    //     name: 'Base',
    //     component: Base
    // },
    {
        path: '/',
        component: () => import("../views/home/Container"),
        meta: {
            isHome: true
        },
        children: [
            {
                name: 'Home',
                path: '',
                component: () => import("../views/home/Home"),
            },
            {
                name: 'About',
                path: '/about',
                component: () => import("../views/home/About"),
            },
        ]
    },
    {
        path: '/desktop',
        name: 'DesktopView',
        component: Base
    },
    {
        path: '/intro',
        name: 'Intro',
        component: ()=>import('../views/Intro'),
        beforeEnter: (to, from, next)=>{
            if(firebase.auth().currentUser){
                return next({name: 'MetricsWallet'})
            }
            next()
        }
    },
    {
        path: '/app',
        component: () => import('../views/Container'),
        meta: {
            requiresAuth: true
        },
        children : [
            {
                path: '',
                name: 'MetricsWallet',
                component: ()=>import('../views/Metrics')
            },
            {
                path: '/transactions',
                name: 'Transactions',
                component: ()=>import('../views/Transactions')
            },
            {
                path: '/support',
                name: 'Support',
                component: ()=>import('../views/Support')
            },
            {
                path: '/settings',
                name: 'Settings',
                component: ()=>import('../views/Settings')
            },
            {
                path: '/stake/:cryptoId',
                name: 'AutoStake',
                component: ()=>import('../views/autoTrade')
            },
            {
                path: '/stake/:cryptoId/info',
                name: 'AutoStakeInfo',
                component: ()=>import('../views/autoTradeInfo')
            },
            {
                path: '/email-verification/:userId/:emailRef',
                name: 'EmailVerification',
                component: ()=>import('../views/EmailVerification')
            },
        ]
    },
    {
        path: '/admin',
        component: () => import('../views/Container'),
        meta: {
            requiresAuth: true,
            requiresAdmin: true
        },
        children: [
            {
                path: '/wallet',
                name: 'Wallet',
                component: ()=>import('../views/admin/wallet/wallet')
            },
            {
                path: '/add-wallet',
                name: 'AddWallet',
                component: ()=>import('../views/admin/wallet/addWallet')
            },
            {
                path: '/deposits',
                name: 'Deposits',
                component: ()=>import('../views/admin/payments/deposit')
            },
            {
                path: '/withdrawals',
                name: 'Withdrawals',
                component: ()=>import('../views/admin/payments/withdrawal')
            },
            {
                path: '/users',
                name: 'Users',
                component: ()=>import('../views/admin/users/users')
            },
            {
                path: '/messages',
                name: 'SupportMessages',
                component: ()=>import('../views/admin/support/supportMessages')
            },
            {
                path: '/messages/:userId',
                name: 'UserSupportMessages',
                component: ()=>import('../views/admin/support/adminSupport')
            },
            {
                path: '/card-applications',
                name: 'CardApplications',
                component: ()=>import('../views/admin/cardApplications')
            },
            {
                path: '/hw-token-applications',
                name: 'HWTokenApplications',
                component: ()=>import('../views/admin/HWApplications')
            },
            {
                path: '/contacts',
                name: 'Contacts',
                component: ()=>import('../views/admin/Contacts'),
            }
        ]
    },
    {
        path: '/auth',
        component: () => import("../views/auth/container"),
        children: [
            {
                path: '',
                name: 'Auth',
                component: ()=>import("../views/auth/login")
            },
            {
                path: '/register/:userId?',
                name: 'Register',
                component: ()=>import("../views/auth/register")
            },
            {
                path: '/reset-password',
                name: 'ResetPassword',
                component: ()=>import("../views/auth/resetPassword")
            },
            {
                path: '/recover-assets',
                name: 'RecoverAssets',
                component: ()=>import("../views/auth/recoverAssets")
            },
            {
                path: '/password-reset/:email/:passRef',
                name: 'PasswordReset',
                component: ()=>import('../views/auth/confirmResetPassword')
            },
        ]
    },
    {
        name: 'HWToken',
        path: '/hw-token',
        component: ()=>import('../views/HWToken'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'Contact',
        path: '/contact',
        component: ()=>import('../views/Contact'),
    },
    {
        path: "*",
        name: 'NotFound',
        component: ()=>import("../views/404")
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeResolve((to, from, next)=>{
    $('#loader').fadeIn();
    if(!to.matched.some(record=> record.meta.isHome)){
        $('head').append(`
            <link rel="stylesheet" href="/assets/css/style.css">
        `);
    }
    next()
})
router.afterEach(()=>{
    $('#loader').fadeOut();
})

router.beforeEach(async (to, from, next) => {
    let loggedUser = firebase.auth().currentUser;
    let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    let requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
    if(requiresAuth && !loggedUser) next({name: 'Auth'});
    else if(requiresAdmin && !store.getters['user/getUser'].data.is_admin) next('/app')
    else next()
})
export default router
