<template>
    <div class="desktop-view">
        <div class="iframe-wrap">
            <iframe
                id="basePrev"
                class="preview-frame"
                :src="`${site_name==='localhost'?'http':'https'}://${site_host}/intro/`"
            >
            </iframe>
        </div>
        <div class="qr">
            <img alt="metrics wallet logo" src="../assets/img/metric-qr-code.png">
            <span class="mt-2">Scan this code to view on Mobile Device</span>
            <button @click="viewIFrame" class="btn btn-primary btn-lg mt-3">
                View on Desktop
            </button>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import '@/assets/css/mobile_preview.css'

export default {
    name: 'Base',
    data(){
        return {
            site_host: window.location.host,
            site_name: window.location.hostname
        }
    },
    methods: {
      viewIFrame(){
          window.location.href = $('#basePrev').attr("src")
      }
    },
    mounted(){
        let check_width = ()=>{
            let width = window.innerWidth;
            // console.log(width);
            if (width < 768) {
                this.$router.replace({name: 'Intro'})
                // window.location.replace("file:///home/shadownetz/Documents/Frameworks/PWA/jaybabani.com/alix-html/app/index.html");
            }
        }
        $(document).ready(function() {
            check_width();
        });
        $(window).resize(function() {
            check_width();
        });
        setTimeout(()=>{
            $('#loader').fadeOut()
        }, 1000);
    }
}
</script>
