import {firestore} from "../../configs/firebase";

class Payment{
    constructor() {
        this._data = new Model()
    }

    static get getNoOfConfirmation(){
        return Math.floor(Math.random() * (10 - 5 + 1)) + 5
    }
}

function Model(){
    this.user = ''; //user-id
    this.code = ''; // user friendly reference code
    this.address = '';
    this.currency = ''; //<fiat-id>
    this.crypto = '';   //<crypto-id>
    this.currency_value = 0;
    this.crypto_value = 0;
    this.created_at = firestore.FieldValue.serverTimestamp();
    this.confirmations = {
        no_of_confirmations: 0,
        required_confirmations : 0,
        updated_at: firestore.FieldValue.serverTimestamp()
    }
    this.updated_at = '';

}

class AutoTrade{
    constructor() {
        this.subscription = {};
        this.old_subscriptions = [];
        this.duration = 0; // days
        this.enabled = false;
        this.earned_amount = 0;
        this.withdrawn = false;
        this.elapse_amount = 0; // 1% amount accumulated so far
        this.elapse_duration = 0; // days (plan duration - elapse plan duration from onset)
        this.elapse_active = false;
        this.elapse_percentage = 0.01;
        this.created_at = firestore.FieldValue.serverTimestamp();
        this.updated_at = firestore.FieldValue.serverTimestamp();
        // this.subscriptions.push(AutoTradeModel.subscriptionModel);
    }


    static get subscriptionModel(){
        return {
            // plan_amount: 0,
            plan_percentage: 0,
            earned_amount: 0,
            amount: 0, // crypto amount
            interest: 0,  // normal interest to be earned for plan duration
            is_active: false, // if normal plan percentage has elapsed
        };
    }
}

export const PaymentModel = Model;
export const AutoTradeModel = AutoTrade;
export default Payment