import {
  card_application_store,
  firestore,
  storageRef,
} from '../configs/firebase';
import globalObjects from '../utils/globalObjects';

class CardApplication{
  constructor(card) {
    this.data = Object.assign(new Model(), card)
  }

  async save(){
    if(this.data.gCard.front){
      this.data.gCard.front = (await CardApplication.uploadImage(this.data.gCard.front)).data.url;
    }
    if(this.data.gCard.back){
      this.data.gCard.back = (await CardApplication.uploadImage(this.data.gCard.back)).data.url;
    }
    if(this.data.vCard.front){
      this.data.vCard.front = (await CardApplication.uploadImage(this.data.vCard.front)).data.url;
    }
    return card_application_store.add(Object.assign({}, this.data))
  }

  static async uploadImage(file){
    const response = new globalObjects.CustomResponse();
    response.data.url = '';
    try{
      const image_ext = file.name.split('.').pop();
      const image_name = `image_${new Date().valueOf()}.${image_ext}`
      const imageRef = storageRef.child(`CardApplications/${image_name}`)
      const upload_snapshot = await imageRef.put(file);
      response.data.url = await upload_snapshot.ref.getDownloadURL();
    }catch (e){
      // console.log(e)
      response.set_status(false, e)
    }
    return Promise.resolve(response)
  }

}

function Model(){
  this.user = '';
  this.gCard = {front: '', back: ''};
  this.vCard = {front: '', back: ''};
  this.ssn = '';
  this.assignedNumber = '';
  this.assignedCVV = '';
  this.status = 'PENDING';   // APPROVED, PENDING, DECLINED
  this.createdAt = firestore.FieldValue.serverTimestamp();
  this.expireAt = firestore.FieldValue.serverTimestamp();
}

export const CardApplicationModel = Model
export default CardApplication