import {transactions_store, users_store} from "../configs/firebase";
import {TransactionModel} from "../models/transaction";

export class UserRepository{

    async creditReferralBonus(referrerId, cryptoId, cryptoAmount, refereeId){
        try{
            const referrer = await users_store.doc(referrerId).get();
            const referee = await users_store.doc(refereeId).get();
            if(referrer.exists && referee.exists){
                const referrer_assets = referrer.data().assets;
                const amount_bonus = Number.parseFloat((cryptoAmount * (10/100)).toFixed(5));
                // eslint-disable-next-line no-prototype-builtins
                if (referrer_assets.hasOwnProperty(cryptoId)){
                    referrer_assets[cryptoId] += amount_bonus;
                } else {
                    referrer_assets[cryptoId] = amount_bonus;
                }
                await users_store.doc(referrerId).update({
                    assets: referrer_assets,
                });
                const new_transaction = new TransactionModel();
                new_transaction.user = referrerId;
                new_transaction.context = 'PROFIT';
                new_transaction.metadata.from = refereeId;
                new_transaction.metadata.to_value = amount_bonus;
                new_transaction.metadata.to = cryptoId;
                new_transaction.description = `RECEIVED ${amount_bonus} ${cryptoId} REFERRAL BONUS FROM ${referee.data().email}`;
                await transactions_store.add(Object.assign({}, new_transaction));
            }
        }catch (e){
            console.log(e);
            return Promise.reject('Could not credit referral bonus')
        }
        return Promise.resolve(null);
    }
}