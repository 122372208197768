export default {
    namespaced: true,
    state: {
        content: {
            title: '',
            message: '',
            buttons: [
                {
                    name: '',
                    callback: ()=>null
                }
            ]
        },
        timestamp: new Date()
    },
    getters: {
        getContent: function(state){
            const {content, timestamp} = state;
            return {content, timestamp}
        }
    },
    mutations: {
        setContent: function (state, payload) {
            state.content = payload;
            state.timestamp = new Date()
        }
    },
    actions: {
        fire(context, {notification_id, timeout = 0}){
            const noti_box = document.getElementsByClassName('notification-box');
            const noti = document.querySelector(`#${notification_id}`);
            // hide all notifications
            for(let box of noti_box){
                box.style.top = '-100%'
            }
            noti.classList.add('noti-show');
            if(timeout > 0){
                setTimeout(()=>{
                    noti.classList.remove('noti-show')
                }, timeout)
            }
        },
        display_noti({commit, dispatch}, {
            message,
            title='',
            context='',
            buttons = [],
            timeout=0
        }){
            commit('setContent', {title, message, buttons});
            switch (context){
                case "icon": {
                    dispatch('fire', {
                        notification_id: 'noti-ios-buttons',
                        timeout
                    })
                    break;
                }
                default: {
                    dispatch('fire', {
                        notification_id: 'noti-ios-default',
                        timeout
                    })
                }
            }
        },
        closeNoti(context, notification_id){
            const noti = document.querySelector(`#${notification_id}`);
            noti.classList.remove('noti-show')
        }
    }
}